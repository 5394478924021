import React, { useState, useEffect } from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
import MainBody from "../components/MainBody";
import moment from "moment";
import _ from "lodash";
import { db } from "../services/firebase";
import TableTimeline from "../components/TableTimeline";
import AlerNodata from "../components/AlertNodata";

function Timeline(props) {
  const { user } = props;
  const [shopSelected, setShopSelected] = useState({});
  const [monthArr, setMonthArr] = useState([]);
  const [monthSelected, setMonthSelected] = useState("all");
  const [notes, setNotes] = useState([]);
  const [modeModal, setModeModal] = useState("");
  const [textNotes, setTextNotes] = useState("");
  const [idNotes, setIdNotes] = useState("");

  function getDataFireStore() {
    let startMounth = moment(monthSelected).startOf('month').toISOString()
    let endMonth =  moment(monthSelected).endOf('month').toISOString()
    debugger
    db.collection("notes")
      .where("timestamp", ">=", startMounth)
      .where("timestamp", "<=", endMonth)
      .onSnapshot(function(querySnapshot) {
        var data = [];
        querySnapshot.forEach(function(doc) {
          data.push({ ...doc.data() });
        });
        debugger
        let data_orderby = _.orderBy(data, "timestamp", "desc");
        setNotes(data_orderby);
        debugger;
      });
  }
  function getAllDataFireStore() {
    db.collection("notes").onSnapshot(function(querySnapshot) {
      var data = [];
      querySnapshot.forEach(function(doc) {
        data.push({ ...doc.data() });
      });
      let data_orderby = _.orderBy(data, "timestamp", "desc");
      setNotes(data_orderby);
      debugger;
    });
  }
  function markPin(id, pin) {
    db.collection("notes")
      .doc(id)
      .update({
        pin: !pin
      });
  }

  function removeNotes(id) {
    db.collection("notes")
      .doc(id)
      .delete()
      .then(function() {
        console.log("Document successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  }
  function setDataModalEdit(note) {
    setModeModal("edit");
    setIdNotes(note.id);
    setTextNotes(note.text);
    setShopSelected(note.shopInfo);
  }
  function saveNotes() {
    let a = {
      text: textNotes,
      username: user
    };
    debugger;
    if (modeModal === "edit") {
      let times = moment().toISOString();
      db.collection("notes")
        .doc(idNotes)
        .update({
          text: textNotes,
          username: user
        })
        .then(res => {
          console.log("==========update Notes success==============");
          console.log("===========update Note success===============");
        });
    }
  }
  useEffect(() => {
    let newMonth = generateMonths();
    debugger;
    setMonthArr([{ label: "All", value: "all" }, ...newMonth]);
  }, []);
  useEffect(() => {
    setNotes([]);
    if (monthSelected === "all") {
      getAllDataFireStore();
    } else {
      getDataFireStore();
    }
  }, [monthSelected]);
  return (
    // <div class="row" style={{ marginTop: 100 }}>
    //   <h1>Timeline</h1>
    // </div>
    <div class="container-fluid" style={{ marginTop: 100 }}>
      <ModalAddNotes
        shopSelected={shopSelected}
        textNotes={textNotes}
        setTextNotes={setTextNotes}
        modeModal={modeModal}
        saveNotes={saveNotes}
      />
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Month</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              value={monthSelected}
              onChange={e => {
                setMonthSelected(e.target.value);
              }}
            >
              {_.map(monthArr, m => {
                return <option value={m.value}>{m.label}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          {notes.length >= 1 && (
            <TableTimeline
              data={notes}
              markPin={markPin}
              removeNotes={removeNotes}
              setDataModalEdit={setDataModalEdit}
            />
          )}
          {notes.length < 1 && <AlerNodata />}
        </div>
        {/* <div class="col-lg-12"></div> */}
      </div>
    </div>
  );
}

export default Timeline;
export const generateMonths = () => {
  let lastYearMonth = moment().subtract(2, "month");
  let monthArrlet = [];
  _.times(3, i => {
    monthArrlet.push({
      label: lastYearMonth.format("MMMM YYYY"),
      value: `${lastYearMonth.format("YYYY-MM")}`
    });
    lastYearMonth.add(1, "month");
  });

  let sortMonthArr = _.orderBy(monthArrlet, "value", "desc");
  return sortMonthArr;
};
const ModalAddNotes = props => {
  function inputTextArea(value) {
    // let text = value.replace(/\r?\n/g,"\\n")
    setTextNotes(value);
  }
  const { shopSelected, saveNotes, setTextNotes, textNotes, modeModal } = props;
  return (
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="ModalAddNotes"
      aria-labelledby="ModalAddNotes"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div id="txt"></div>
            <h5 class="modal-title">
              {shopSelected.Value
                ? "Shop Name : " + shopSelected.Value
                : shopSelected.UserName
                ? "Usrname : " + shopSelected.UserName
                : ""}
              <em
                style={{ color: "grey" }}
              >{` (${shopSelected.CustomerId})`}</em>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={e => {
                setTextNotes("");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <textarea
                  rows="10"
                  cols="50"
                  onChange={e => {
                    inputTextArea(e.target.value);
                  }}
                  value={textNotes}
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={e => {
                setTextNotes("");
              }}
            >
              Close
            </button>
            <button
              type="button"
              class={`btn btn-${
                modeModal === "create" ? "success" : "primary"
              }`}
              onClick={saveNotes}
              data-dismiss="modal"
            >
              {modeModal === "create" ? "Create" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
