/*global chrome*/

const firebase = require("firebase");
// Required for side-effects
var firebaseConfig = {
  apiKey: "AIzaSyBHeiiF_lxRgxWINP892c6IJj02MazyUzY",
  authDomain: "niceloop-support-timestamp.firebaseapp.com",
  databaseURL: "https://niceloop-support-timestamp.firebaseio.com",
  projectId: "niceloop-support-timestamp",
  storageBucket: "niceloop-support-timestamp.appspot.com",
  messagingSenderId: "31510034676",
  appId: "1:31510034676:web:803fe3778ae9c416a0c6a1",
  measurementId: "G-TCWZ0WEWF0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export let db = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();
// provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
// provider.addScope("192.168.2.111:3000/auth/contacts.readonly");

firebase.auth().languageCode = "us";
provider.setCustomParameters({
  login_hint: "user@example.com"
});
export const firebase2 = firebase;
export const provider2 = provider;
let firstLoadUser = -1;

export const getUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.

        var displayName = user.displayName;
        var email = user.email;
        var emailVerified = user.emailVerified;
        var photoURL = user.photoURL;
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        var providerData = user.providerData;
        resolve({ user });
        if (firstLoadUser === null) {
          window.location.reload(true);
        }
        // ...
      } else {
        // User is signed out.
        // ...
        firstLoadUser = null;
        resolve({});
       // window.location.reload(true);
      }
    });
    
  });
};
