import React, { useState, useEffect } from "react";
import "./App.css";
import Nav from "./components/Nav";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import MainBody from "./components/MainBody";
import Home from "./pages/Home";
import Timeline from './pages/Timeline'
import { db } from "./services/firebase";
import Swal from "sweetalert2";

function App(props) {
  const { user } = props;
  const [isAdmin, setIsAdmin] = useState(null);
  const [navSelected, setNavSelected] = useState('home');

  // useEffect(() => {
  //   if (user) {
  //     fetchUser(user);
  //   }
  // }, [user]);
  function fetchUser(user) {
    db.collection("user")
      .doc(user.uid)
      .get()
      .then(function(doc) {
        let data = doc.data();
        isAdmin(data.isAdmin);
        ;
        // if (doc.exists) {
        //   console.log("Document data:", doc.data());
        //   
        // } else {
        //   // doc.data() will be undefined in this case
        //   console.log("No such document!");
        //   saveUserToFireStore(user);
        //   
        // }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  }
  function saveUserToFireStore(user) {
    db.collection("user")
      .doc(user.uid)
      .set({
        uid: user.uid,
        username: user.email
      })
      .then(function() {
        alert("saveUsers");
      });
  }
  return (
    <div className="App">
      <Nav user={user.email} navSelected={navSelected} setNavSelected={setNavSelected}/>
      <div class="container-fluid">

        {navSelected === 'home' && <Home user={user.email} />}
        {navSelected === 'timeline' && <Timeline user={user.email}/>}
      </div>
    </div>
  );
}

export default App;
