


import React, { useState, useEffect } from "react";
import {firebase2} from '../services/firebase'

const AlertNodata = (props) => {
    function signout(params) {
        firebase2.auth().signOut().then(()=>{
        //   alert('signout')
          window.location.reload(true);
        })
      }
    return (
        <div class="row text-center mt-5">
            <div class="col-lg-12">
            <div class="alert alert-light" role="alert">
                <h4 class="alert-heading">No Authorization</h4>
                <p>
                Your gmails is not authorization  please contract to it support
                </p>
                
              </div>
              <button type="button" class="btn btn-primary" onClick={signout}>Login Again</button>

            </div>
        </div>
      );
};
export default AlertNodata;
