/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import moment from 'moment'

function RightSideBar(props) {
  const { shopSelected } = props;
  const [shopData, setShopData] = useState([]);
  ;
  async function getDataForRightBar() {
    let url =
      "https://asia-east2-niceloop-support-timestamp.cloudfunctions.net/shopInfo";
    let param = {
      payload: shopSelected.CustomerId
    };
    ;
    let data = await axios.post(url, param).then(res => {
      return res.data;
    });
    setShopData(data);
    ;
  }
  useEffect(() => {
    getDataForRightBar();
  }, [shopSelected]);
  return (
    <nav class="col-md-3 d-none d-md-block bg-light sidebar-right">
      <div class="sidebar-sticky mt-5 ml-3  ">
        <div class="row">
          {_.map(shopData[0], (s, key) => {
            
            return (
              <div class="col-lg-12 mt-1">
                <p>
                  {key} : {key === 'CreateDate' ? moment(s).format('YYYY-MM-DD HH:mm') : key === 'ExpireDate' ? moment(s).format('YYYY-MM-DD HH:mm') : s}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </nav>
  );
}

export default RightSideBar;
