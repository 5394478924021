/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

function LeftSideBar(props) {
  let radio = [
    { name: "ShopName", value: "shopname" },
    { name: "ID", value: "id" },
    { name: "User", value: "user" }
  ];
  const [radioSelected, setRadioSelected] = useState("shopname");
  const { shopSelected, setShopSelected } = props;
  const [shops, setShops] = useState([]);
  const [searchText, setSearchText] = useState("");
  async function search() {
    let url =
      "https://asia-east2-niceloop-support-timestamp.cloudfunctions.net/search";
    if (radioSelected) {
      if (searchText) {
        let data = await axios
          .post(url, { payload: searchText, type: radioSelected.toUpperCase() })
          .then(res => {
            console.log("====================================");
            console.log(res);
            console.log("====================================");
            return res.data;
          });

        setShops(data);
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาพิมพ์ข้อความค้นหา"
          // text: 'กรุณาเลือกหมวดหมู่ในการค้นหา',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "กรุณาเลือกหมวดหมู่ในการค้นหา"
        // text: 'กรุณาเลือกหมวดหมู่ในการค้นหา',
        // footer: '<a href>Why do I have this issue?</a>'
      });
    }
  }
  async function resetSearch() {
    //setRadioSelected("");
    setShops([]);
    setSearchText("");
    let url =
      "https://asia-east2-niceloop-support-timestamp.cloudfunctions.net/search";
    let data = await axios
      .post(url, { payload: "", type: "LAST30" })
      .then(res => {
        console.log("====================================");
        console.log(res);
        console.log("====================================");
        return res.data;
      });

    setShops(data);
  }
  useEffect(() => {
    setShopSelected({});
  }, [radioSelected]);
  useEffect(() => {
    resetSearch({});
  }, []);
  return (
    <nav class="col-md-3 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky m-5">
        <div class="row"></div>
        <div class="row">
          {_.map(radio, r => {
            return (
              <Radio
                name={r.name}
                value={r.value}
                radioSelected={radioSelected}
                setRadioSelected={setRadioSelected}
                setShops={setShops}
                setSearchText={setSearchText}
              />
            );
          })}
        </div>
        <div class="row mt-3">
          <div class="col-lg-9">
            <SeachBox
              search={search}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
          <div class="col-lg-3 pl-0 text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              onClick={resetSearch}
              // style={{ paddingBottom: 7, paddingRight: 5 }}
            >
              <i class="material-icons mr-1">refresh</i>
            </button>
          </div>
          <div class="col-lg-12">
            <p>reslut : {shops.length}</p>
          </div>
          {shops && (
            <div
              class="col-lg-12"
              style={{ maxHeight: 500, overflowY: "auto" }}
            >
              <div class="list-group " style={{}}>
                {_.map(shops, s => {
                  return (
                    <a
                      class={`list-group-item list-group-item-action 
                      ${shopSelected.Id === s.Id ? "active text-white" : ""}`}
                      onClick={e => {
                        setShopSelected(s);
                      }}
                    >
                      {radioSelected === "user" ? s.UserName : s.Value}
                      <em
                        style={{
                          color: shopSelected.Id === s.Id ? "white" : "grey"
                        }}
                      >{` (${s.CustomerId})`}</em>
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default LeftSideBar;

const SeachBox = props => {
  const { search, searchText, setSearchText } = props;
  const handleKeyPress = event => {
    
    if (event.key === "Enter") {
      search()
    }
  };
  return (
    <div class="input-group mb-2 mr-sm-2 ">
      <input
        type="text"
        class="form-control"
        id="inlineFormInputGroupUsername2"
        placeholder="Search"
        value={searchText}
        onChange={e => {
          setSearchText(e.target.value);
        }}
        onKeyPress={handleKeyPress}
      />
      <div class="input-group-append">
        <button  class="btn btn-primary btn-sm" onClick={search} type="submit"  >
          submit
        </button> 
      </div>
    </div>
  );
};

const Radio = props => {
  const { setRadioSelected, radioSelected,setSearchText } = props;
  const { name, value, setShops } = props;
  function changeRadioValue(value) {
    setRadioSelected(value);
    setShops([]);
    // setSearchText('')
  }
  return (
    <div
      class="form-check"
      style={{ marginLeft: 15 }}
      onClick={e => {
        changeRadioValue(e.target.value);
      }}
    >
      <input
        class="form-check-input"
        type="radio"
        name={name}
        id={name}
        value={value}
        checked={radioSelected === value ? true : false}
      />
      <label class="form-check-label" for={name}>
        {name}
      </label>
    </div>
  );
};
