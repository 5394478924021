import React from "react";
import { firebase2 } from "../services/firebase";
import '../Nav.css'

function Nav(props) {
  const { user, navSelected, setNavSelected } = props;
  function signout(params) {
    firebase2
      .auth()
      .signOut()
      .then(() => {
        alert("signout");
        window.location.reload(true);
      });
  }
  return (
    <nav class="navbar navbar-primary fixed-top bg-primary flex-md-nowrap shadow">
      <a class="navbar-brand  text-white" href="#">
        Web Support
      </a>
      <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist" 
      // style={{marginRight:600}}
      >
        <a
          class="nav-item nav-link active"
          id="nav-tab"
          data-toggle="tab"
          href="#nav-profile"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="true"
          onClick={e=>{setNavSelected('home')}}
        >
          Home
        </a>
        <a
          class="nav-item nav-link"
          id="nav-tab"
          data-toggle="tab"
          href="#nav-profile"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
          onClick={e=>{setNavSelected('timeline')}}
        >
          Timeline
        </a>
        
      </div>
      <div class="row text-right">
        <ul class="navbar-nav px-3">
          <li class="nav-item text-nowrap ">
            <a class="nav-link text-white" href="#">
              {user}
            </a>
          </li>
        </ul>
        <ul class="navbar-nav px-3">
          <button
            type="button"
            class="btn btn-outline-warning"
            onClick={signout}
          >
            singout
          </button>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
