/*global chrome*/

import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { provider2, firebase2 } from "../services/firebase";
import "../Login.css";
import Swal from 'sweetalert2'
// import {setLoading} from '../data/actions'

const Login = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  function singinExtension() {
    // const provider = new firebase.auth.GoogleAuthProvider();
    // Request an OAuth token from the Chrome Identity API.
    chrome.identity.getAuthToken({ interactive: true }, function(token) {
      console.log("token");
      console.log(token);
      if (chrome.runtime.lastError) {
        console.log("It was not possible to get a token programmatically.");
      } else if (chrome.runtime.lastError) {
        console.error(chrome.runtime.lastError);
      } else if (token) {
        // Authorize Firebase with the OAuth Access Token.
        var credential = firebase.auth.GoogleAuthProvider.credential(
          null,
          token
        );
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(res => {
            setUsername(res.user.email);
          })
          .catch(function(error) {
            // The OAuth token might have been invalidated. Lets' remove it from cache.
            if (error.code === "auth/invalid-credential") {
              chrome.identity.removeCachedAuthToken(
                { token: token },
                function() {}
              );
            }
          });
      } else {
        console.error("The OAuth Token was null");
      }
    });
  }

  function singinWeb() {
    // const provider = new firebase.auth.GoogleAuthProvider();

    firebase2
      .auth()
      .signInWithPopup(provider2)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        
        setUsername(user.email);
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    //  firebase2.auth().signInWithRedirect(provider2);
  }
  function checkSignIn() {
    if (window.chrome && chrome.runtime && chrome.runtime.id) {
      singinExtension();
    } else {
      singinWeb();
    }
  }

  function clear() {
    firebase
      .auth()
      .signOut()
      .then(function() {
        alert("singout");
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  }
  useEffect(() => {
   
  }, [])
  return (
    <div className="Login">
      <div class="container" id="Login">
        <div class="card shadow">
          <div class="card-header card-header-divider text-center">
            <h4>Web It Support</h4>
          </div>

          <div class="row">
            <div class="col-12">
              <h5 class="text-center">{username}</h5>
              {username && <hr />}

              {username && <h5 class=" mt-5 text-center" style={{color:'green'}}>Login successful.</h5>}
              {username && (
                <h5 class="text-center" style={{color:'green'}}>please re-open app again.</h5>
              )}
            </div>
            <div class="col-12 mb-5 p-5 text-center">
              {/* <button
                type="button"
                onClick={checkSignIn}
                class="btn btn-primary btn-lg btn-block"
              >
                <b>Sign in with google</b>
              </button> */}
              <BtnGoogle onClick={checkSignIn}/>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;

const BtnGoogle = (props) => {
  const {onClick} = props
  return(
    <button type="button" class="google-button  " onClick={onClick}>
    <span class="google-button__icon">
      <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg" style={{marginBottom:10}}>
        <path
          d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
          id="Shape"
          fill="#EA4335"
        />
        <path
          d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
          id="Shape"
          fill="#FBBC05"
        />
        <path
          d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
          id="Shape"
          fill="#4285F4"
        />
        <path
          d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
          fill="#34A853"
        />
      </svg>
    </span>
    <span class="google-button__text">Sign in with Google</span>
  </button>
  )
}
