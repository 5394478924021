import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

function CardTable(props) {
  const { tabSelected, data, removeNotes, markPin, setDataModalEdit } = props;
  const [notes, setNotes] = useState({});

  function mapnewData() {
    let newData = {};
    _.forEach(data, d => {
      let time = moment(d.timestamp).format("YYYY-MM-DD");
      if (newData[time] === undefined) {
        newData[time] = [];
      }
      newData[time].push(d);
    });
    setNotes(newData);
    debugger;
  }
  function remove(id) {
    Swal.fire({
      title: "Do you want delete this note",
      // text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons:true
    }).then(result => {
      if (result.value) {
        removeNotes(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }
  useEffect(() => {
    mapnewData();
  }, [data]);
  return (
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table table-striped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Shop</th>
              <th scope="col">User</th>
              <th scope="col">Text</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {_.map(notes, (n, key) => {
              return [
                // <table>
                //   <thead></thead>
                //   <tbody>
                <tr
                  style={{
                    height: 100
                    // backgroundColor:'rgba(0,0,0,.05)'
                  }}
                >
                  <td colspan="5">
                    <h4 style={{ marginTop: 60, marginLeft: 8 }}>{key}</h4>{" "}
                  </td>
                </tr>,
                //   </tbody>
                // </table>,
                _.map(n, _n => {
                  return (
                    <tr>
                      <td style={{ width: 180 }}>
                        {moment(_n.timestamp).format("YYYY-MM-DD ") +
                          moment(_n.timestamp).format(" @ HH:mm")}
                      </td>
                      <td>
                        {_n.shopInfo.Value}
                        <em
                          style={{ color: "grey" }}
                        >{` (${_n.shopInfo.CustomerId})`}
                        </em>
                      </td>
                      <td>{_n.username}</td>
                      <td>
                        {" "}
                        {/* <i class="material-icons mr-1">message</i> */}
                        {_n.text
                          ? _.map(_.split(_n.text, "\n"), t => {
                              return (
                                <p style={{ marginBottom: "0.2rem" }}>{t}</p>
                              );
                            })
                          : ""}
                      </td>
                      <td style={{ width: 260 }}>
                        <button
                          type="button"
                          class="btn btn-link"
                          onClick={e => {
                            markPin(_n.id, _n.pin);
                          }}
                        >
                          <i
                            class="material-icons md-24"
                            style={{ color: _n.pin ? "orange" : "" }}
                          >
                            {" "}
                            {_n.pin ? "star" : "star_border"}
                          </i>

                          {/* {_n.pin ? "Unpin" : "Pin"} */}
                        </button>
                        <button
                          type="button"
                          class="btn btn-link"
                          data-toggle="modal"
                          data-target="#ModalAddNotes"
                          onClick={e => {
                            setDataModalEdit(_n);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          class="btn btn-link"
                          onClick={e => {
                            remove(_n.id);
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })
              ];
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CardTable;
const BtnIcon = props => {
  const { name, colorClass, style } = props;
  return (
    <button
      type="button"
      class={`btn btn-${colorClass ? colorClass : ""}`}
      style={style}
    >
      <i class="material-icons">{name}</i>
    </button>
  );
};
