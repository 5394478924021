import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getUser } from "./services/firebase";
import Login from "./pages/login";
import { db } from "./services/firebase";
import NoAut from './components/NoAut'

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
getUserAndRender();

async function getUserAndRender() {

  // loding...
  let res = await getUser();
  ;

  // too many if
  if (res.user) {
    let isAdmin = await fetchUser(res.user);
    debugger
    if (isAdmin) {
      ReactDOM.render(<App user={res.user} />, document.getElementById("root"));
      serviceWorker.unregister();
    } else {
        ReactDOM.render(<NoAut/>, document.getElementById("root"));
        serviceWorker.unregister();
    }
  } else {
    ReactDOM.render(<Login />, document.getElementById("root"));
    serviceWorker.unregister();
  }
}
async function fetchUser(user) {
  let isAdmin = null;
  // isAdmin = await db
  //   .collection("user")
  //   .doc(user.uid)
  //   .get()
  //   .then(function(doc) {
  //     let data = doc.data();
  //     return data.isAdmin ? data.isAdmin : null;
  //     // if (doc.exists) {
  //     //   console.log("Document data:", doc.data());
  //     //   
  //     // } else {
  //     //   // doc.data() will be undefined in this case
  //     //   console.log("No such document!");
  //     //   saveUserToFireStore(user);
  //     //   
  //     // }
  //   })
  //   .catch(function(error) {
  //     console.log("Error getting document:", error);
  //   });
  isAdmin = await db
    .collection("user")
    .where('username','==',user.email)
    .get()
    .then(function(querySnapshot) {
      let data  = null
      querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
           data = doc.data()
          debugger
      });
      return data.isAdmin ? data.isAdmin : null;

  })
    .catch(function(error) {
      console.log("Error getting document:", error);
    });
debugger
  return isAdmin;
}
