import React from "react";
import _ from "lodash";

function CardTable(props) {
  const { tabSelected, data } = props;
  ;
  return (
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style={{ borderBottom: "none" }}>
                {tabSelected === "setting"
                  ? "Name"
                  : tabSelected === "users"
                  ? "Username"
                  : "Emails"}
              </th>
              {tabSelected !== "emails" &&
                <th scope="col" style={{ borderBottom: "none" }}>
                {tabSelected === "setting"
                  ? "Value"
                  : tabSelected === "users"
                  ? "name"
                  : ""}
              </th>
              }
              {/* <th scope="col" style={{ borderBottom: "none" }}>
                <button type="button" class="btn btn-primary">
                  Add New Row
                </button>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {_.map(data, d => {
              ;
              return (
                <tr>
                  <td>
                    {tabSelected === "setting"
                      ? d.Name
                      : tabSelected === "users"
                      ? d.UserName
                      : d.Email}
                  </td>
                  {tabSelected !== "emails" && (
                    <td>
                      {tabSelected === "setting"
                        ? d.Value
                        : tabSelected === "users"
                        ? d.Name
                        : ""}
                    </td>
                  )}

                  {/* <td>{d.Value}</td> */}
                  {/* <td>
                    <BtnIcon name="edit" colorClass="light"></BtnIcon>
                    <BtnIcon
                      name="delete"
                      colorClass="light"
                      style={{ marginLeft: 10 }}
                    />
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CardTable;
const BtnIcon = props => {
  const { name, colorClass, style } = props;
  return (
    <button
      type="button"
      class={`btn btn-${colorClass ? colorClass : ""}`}
      style={style}
    >
      <i class="material-icons">{name}</i>
    </button>
  );
};
