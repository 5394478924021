import React, { useState } from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
import MainBody from "../components/MainBody";

function Home(props) {
  const {user} = props
  const [shopSelected, setShopSelected] = useState({});
  return (
    <div class="row" style={{ marginTop: 100 }}>
      <div class="col-lg-3">
        <LeftSideBar
          setShopSelected={setShopSelected}
          shopSelected={shopSelected}
        />
      </div>

      {shopSelected.CustomerId && (
        <div class="col-lg-6">
          <MainBody shopSelected={shopSelected} user={user}/>
        </div>
      )}
      {shopSelected.CustomerId && (
        <div class="col-lg-3">
          <RightSideBar shopSelected={shopSelected}/>
        </div>
      )}
    </div>
  );
}

export default Home;
