import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import _ from 'lodash'

function CardNotes(props) {
  const { data = {}, markPin, removeNotes, setDataModalEdit } = props;
  function remove(id) {
    Swal.fire({
      title: "Do you want delete this note",
      // text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons:true
    }).then(result => {
      if (result.value) {
        removeNotes(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }
  return (
    <div
      class="card shadow mt-3"
      style={{ border: data.pin ? "2px solid red" : "" }}
    >
      <div class="card-body ">
        <div class="row">
          <div class="col-lg-12">
            {/* <i class="material-icons mr-1">message</i> */}
              {data.text ? _.map(_.split(data.text,'\n'),t=>{
                return(
                <p style={{marginBottom:'0.2rem'}}>{t}</p>
                )
              }) : ''}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-3  pr-0">
            {/* <i class="material-icons mr-1">message</i> */}
            <small>
              <em style={{ color: "grey" }}>
                {moment(data.timestamp).format("YYYY-MM-DD HH:mm")}
              </em>
            </small>
          </div>
          <div class="col-lg-3 pl-0 pr-0">
            {/* <i class="material-icons mr-1">message</i> */}
            <small>
              <em style={{ color: "grey" }}>{data.username}</em>
            </small>
          </div>
          <div class="col-lg-6 text-right">
            {/* <i class="material-icons mr-1">message</i> */}
            <button
              type="button"
              class="btn btn-link"
              onClick={e => {
                markPin(data.id, data.pin);
              }}
            >
              {data.pin ? "Unpin" : "Pin"}
            </button>
            <button
              type="button"
              class="btn btn-link"
              data-toggle="modal"
              data-target="#ModalAddNotes"
              onClick={e => {
                setDataModalEdit(data);
              }}
            >
              Edit
            </button>
            <button
              type="button"
              class="btn btn-link"
              onClick={e => {
                remove(data.id);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNotes;
