


import React, { useState, useEffect } from "react";
import moment from 'moment'


const AlertNodata = (props) => {
let time  = moment().format()
    return (
        <div class="row text-center">
            <div class="col-lg-12">
            <div class="alert alert-light" role="alert">
                <h4 class="alert-heading">No Data</h4>
                <p>
                This data cannot be found.
                </p>
                <p>
                {time}
                </p>
                
              </div>
            </div>
        </div>
      );
};
export default AlertNodata;
