/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import CardTable from "./CardTable";
import CardNotes from "./CardNotes";
import { db } from "../services/firebase";
import moment from "moment";
import axios from "axios";
import AlertNodata from "./AlertNodata";

function Main(props) {
  const { user } = props;
  let tabs = [
    { name: "Notes", value: "notes" },
    { name: "Setting", value: "setting" },
    { name: "Users", value: "users" },
    { name: "Emails", value: "emails" }
  ];
  const [tabSelected, setTabSelected] = useState("notes");
  const { shopSelected } = props;
  const [notesold, setNotesold] = useState([]);
  const [notes, setNotes] = useState([]);
  const [textNotes, setTextNotes] = useState("");
  const [idNotes, setIdNotes] = useState("");
  // const [username, setUsername] = useState("eemeemgame@gmail.com");
  const [orderTimes, setOrderTimes] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [modeModal, setModeModal] = useState("");
  const [filterSelcted, setFilterSelected] = useState("all");

  let select_filter = [
    { name: "All", value: "all" },
    { name: "Pin", value: "pin" },
    { name: "Upin", value: "unpin" }
  ];

  function getDataFireStore() {
    db.collection("notes")
      .where(
        "shopInfo.CustomerId",
        "==",
        (shopSelected.CustomerId && shopSelected.CustomerId) || ""
      )
      .onSnapshot(function(querySnapshot) {
        var data = [];
        querySnapshot.forEach(function(doc) {
          data.push({ ...doc.data() });
        });
        let data_orderby = _.orderBy(data, "timestamp", "desc");
        setNotes(data_orderby);
        setNotesold(data_orderby);
        debugger;
      });
  }
  function markPin(id, pin) {
    db.collection("notes")
      .doc(id)
      .update({
        pin: !pin
      });
  }
  async function insertNotes() {
    let shopFecth = await fetchShopInfo(shopSelected.CustomerId)
    debugger;
    if (modeModal === "create") {
      let times = moment().toISOString();
      db.collection("notes")
        .doc(times)
        .set({
          id: times,
          shopInfo: shopFecth[0],
          text: textNotes,
          timestamp: moment().toISOString(),
          username: user,
          pin: false
        })
        .then(res => {
          console.log("==========set success==============");
          console.log("===========set success===============");
          setFilterSelected("All");
          setOrderTimes(true);
        });
    }
    if (modeModal === "edit") {
      let times = moment().toISOString();
      db.collection("notes")
        .doc(idNotes)
        .update({
          text: textNotes,
          username: user
        })
        .then(res => {
          console.log("==========update Notes success==============");
          console.log("===========update Note success===============");
        });
    }
  }
  function removeNotes(id) {
    db.collection("notes")
      .doc(id)
      .delete()
      .then(function() {
        console.log("Document successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  }
  async function getDataCardTable() {
    let url =
      "https://asia-east2-niceloop-support-timestamp.cloudfunctions.net/setting";
    let param = {
      payload: shopSelected.CustomerId,
      type: tabSelected.toUpperCase()
    };
    let data = await axios.post(url, param).then(res => {
      return res.data;
    });
    setDataTable(data);
  }
  function setDataModalEdit(note) {
    setModeModal("edit");
    setIdNotes(note.id);
    setTextNotes(note.text);
  }
  function processFilter() {
    if (filterSelcted === "all") {
      getDataFireStore();
    }
    if (filterSelcted === "pin") {
      let newNotes = _.cloneDeep(notesold);
      let newNotes_filter = _.filter(newNotes, n => {
        return n.pin === true;
      });
      setNotes(newNotes_filter);
    }
    if (filterSelcted === "unpin") {
      let newNotes = _.cloneDeep(notesold);
      let newNotes_filter = _.filter(newNotes, n => {
        return n.pin === false;
      });
      setNotes(newNotes_filter);
    }
  }
  function processOrderByTimeStamp() {
    if (orderTimes) {
      let newNotes = _.cloneDeep(notes);
      let newNotes_orderBy = _.orderBy(newNotes, "timestamp", "desc");
      setNotes(newNotes_orderBy);
    } else {
      let newNotes = _.cloneDeep(notes);
      let newNotes_orderBy = _.orderBy(newNotes, "timestamp", "asc");
      setNotes(newNotes_orderBy);
    }
  }
  async function fetchShopInfo(customerId) {
    let url =
      "https://asia-east2-niceloop-support-timestamp.cloudfunctions.net/search";
    let shop = await axios
      .post(url, { payload: customerId, type: "ID" })
      .then(res => {
        console.log("====================================");
        console.log(res);
        console.log("====================================");
        return res.data;
      });

    return shop
    debugger;
  }
  useEffect(() => {
    getDataFireStore();
    processOrderByTimeStamp();
  }, [shopSelected]);
  useEffect(() => {
    if (tabSelected !== "notes") {
      setDataTable([]);
      getDataCardTable();
    }
  }, [shopSelected, tabSelected]);
  useEffect(() => {
    processFilter();
  }, [filterSelcted]);
  useEffect(() => {
    processOrderByTimeStamp();
  }, [orderTimes]);

  return (
    <div>
      <ModalAddNotes
        shopSelected={shopSelected}
        textNotes={textNotes}
        insertNotes={insertNotes}
        setTextNotes={setTextNotes}
        modeModal={modeModal}
      />

      <div class="row">
        <div class="col-lg-12">
          <h4>
            {(shopSelected.Value && shopSelected.Value) ||
              (shopSelected.Name && shopSelected.Name)}
          </h4>
        </div>
        <div class="col-lg-12">
          <Tabs
            tabs={tabs}
            setTabSelected={setTabSelected}
            tabSelected={tabSelected}
          />
        </div>
        <div class="col-lg-6 mt-3">
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            hidden={
              tabSelected === "notes" && shopSelected.CustomerId ? false : true
            }
            value={filterSelcted}
            onChange={e => {
              setFilterSelected(e.target.value);
            }}
          >
            {_.map(select_filter, s => {
              return <option value={s.value}>{s.name}</option>;
            })}
          </select>
        </div>
        <div class="col-lg-6 mt-3 text-right">
          <button
            hidden={
              tabSelected === "notes" && shopSelected.CustomerId ? false : true
            }
            type="button"
            class="btn btn-light"
            onClick={e => {
              setOrderTimes(!orderTimes);
            }}
          >
            timestamp
            <i class="material-icons mr-1">
              {orderTimes ? "arrow_drop_down" : "arrow_drop_up"}
            </i>
          </button>

          <BtnInsert
            hidden={
              tabSelected === "notes" && shopSelected.CustomerId ? false : true
            }
            setTextNotes={setTextNotes}
            setModeModal={setModeModal}
          />
        </div>
        {tabSelected === "notes" && notes.length < 1 && (
          <div class="col-lg-12 text-center">
            <AlertNodata />
          </div>
        )}
        {tabSelected === "notes" && (
          <div class="col-lg-12 mt-2">
            {_.map(notes, n => {
              return (
                <CardNotes
                  data={n}
                  markPin={markPin}
                  removeNotes={removeNotes}
                  setDataModalEdit={setDataModalEdit}
                />
              );
            })}
          </div>
        )}
        {tabSelected !== "notes" && (
          <div
            class="col-lg-12 mt-3"
            style={{ maxHeight: 600, overflowY: "auto" }}
          >
            <CardTable tabSelected={tabSelected} data={dataTable} />
          </div>
        )}
      </div>
    </div>
  );
}

const Tabs = props => {
  const { tabs } = props;
  const { tabSelected, setTabSelected } = props;
  return (
    <ul class="nav justify-content-center bg-light  nav-tabs text-primary">
      {_.map(tabs, t => {
        return (
          <li
            class="nav-item"
            onClick={e => {
              setTabSelected(t.value);
            }}
          >
            <a
              class={`nav-link ${
                tabSelected === t.value ? "active text-white bg-primary" : ""
              }`}
            >
              {t.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Main;
const BtnInsert = props => {
  const { hidden, setTextNotes, setModeModal } = props;
  return (
    <button
      type="button"
      class="btn btn-success shadow"
      data-toggle="modal"
      data-target="#ModalAddNotes"
      style={{ paddingLeft: 3 }}
      hidden={hidden}
      onClick={e => {
        setTextNotes("");
        setModeModal("create");
      }}
    >
      <i class="material-icons mr-1">add_circle</i>
      Insert
    </button>
  );
};

const ModalAddNotes = props => {
  function inputTextArea(value) {
    // let text = value.replace(/\r?\n/g,"\\n")
    setTextNotes(value);
  }
  const {
    shopSelected,
    insertNotes,
    setTextNotes,
    textNotes,
    modeModal
  } = props;
  return (
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="ModalAddNotes"
      aria-labelledby="ModalAddNotes"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div id="txt"></div>
            <h5 class="modal-title">
              {shopSelected.Value
                ? "Shop Name : " + shopSelected.Value
                : shopSelected.UserName
                ? "Usrname : " + shopSelected.UserName
                : ""}
              <em
                style={{ color: "grey" }}
              >{` (${shopSelected.CustomerId})`}</em>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={e => {
                setTextNotes("");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <textarea
                  rows="10"
                  cols="50"
                  onChange={e => {
                    inputTextArea(e.target.value);
                  }}
                  value={textNotes}
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={e => {
                setTextNotes("");
              }}
            >
              Close
            </button>
            <button
              type="button"
              class={`btn btn-${
                modeModal === "create" ? "success" : "primary"
              }`}
              onClick={insertNotes}
              data-dismiss="modal"
            >
              {modeModal === "create" ? "Create" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
